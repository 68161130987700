import { useEffect, useState } from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import hero from './hero.jpg'
import about from './about.jpg'
import './home.css'
import tools_1 from './tools/tool-1.png'
import tools_2 from './tools/tool-2.png'
import tools_3 from './tools/tool-3.png'
import tools_4 from './tools/tool-4.png'
import tools_5 from './tools/tool-5.png'
import tools_6 from './tools/tool-6.png'
import tools_7 from './tools/tool-7.png'
import tools_8 from './tools/tool-8.svg'
import tools_9 from './tools/tool-9.png'
import tools_10 from './tools/tool-10.png'
import tools_11 from './tools/tool-11.svg'
import tools_12 from './tools/tool-12.png'
import tools_13 from './tools/tool-13.png'
import tools_14 from './tools/tool-14.png'
import tools_15 from './tools/tool-15.png'
import pricingImage from './pricing-1.svg'
import industry_1 from './industries/industry-1.jpg'
import industry_2 from './industries/industry-2.jpg'
import industry_3 from './industries/industry-3.jpg'
import industry_4 from './industries/industry-4.jpg'
import industry_5 from './industries/industry-5.jpg'
import industry_6 from './industries/industry-6.jpg'

import service_1 from './services/service-1.png'
import service_2 from './services/service-2.png'
import service_3 from './services/service-3.png'
import service_4 from './services/service-4.png'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validate } from 'react-email-validator';

export default function Home() {
  const [name, setName] = useState()
  const [message, setMessage] = useState()
  const [phone, setPhone] = useState()
  const [subject, setSubject] = useState()
  const [emailValue, setEmailValue] = useState()
  const [emailValid, setEmailValid] = useState(true)
  const postUrl = "https://mail.omarwasfi.com/EMail/SubmitSurrealanceContactForm"

  const notify = () => toast.success('Success', {
    className: 'toast-message'
  });
  const notValidNotify = () => toast.error('Not a valid email', {
    className: ''
  });


  function Handlesubmit(e) {

    e.preventDefault()
    const form = document.getElementById('email-form');
    let data = {
      name: name,
      email: emailValue,
      phoneNumber: phone,
      subject: subject,
      message: message,
    }
    //console.log(data)
    if (validate(emailValue)) {

      form.reset()
      setEmailValue('')
      notify()
      //console.log("submit")
      fetch(postUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json-patch+json',
          'accept': 'text/plain'
        },
        body: JSON.stringify(data)
      })
        //.then(response => response.json())
        .then(data => {
          // console.log('Success:', data);
          data = ''
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    } else {
      setEmailValid(false)
      notValidNotify()
    }
  }



  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    let yOffset = -80;
    if (window.scrollY === 0) {
      yOffset = -150;
    }
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });

  }

  const toolsResponsive = {

    0: {
      items: 3,
    },
    450: {
      items: 3,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 6,
    },
  }

  return (
    <div className='homepage' id='home'>
      <section className='hero container first-section' data-aos="fade-left" >
        <div className='col-12 col-lg-6 '>
          <h1>Surrealance Multiservice</h1>
          <p>A call center that aims to deliver great business service experience that you truly deserve</p>
          <Link to="/#pricing" scroll={el => scrollWithOffset(el)}><button>Get Started</button></Link>
        </div>
        <div className='col-12 col-lg-6'><img src={hero} alt="lead generation company" /></div>
      </section>
      <section className='about container' id='about' data-aos="fade-right">

        <div className='image-container col-lg-5'>
          <img src={about} alt="Surrealance Story" />
        </div>
        <div className='col-lg-7 about-right'>
          <h1>What makes us special ?</h1>
          <p>At S.M.S., our top priority is making sure that our clients are satisfied by ensuring their money is invested safely and that their time and resources are used wisely.</p>
          <p>We want to work with clients and representatives for a very long time, and the only way we can achieve that is by creating a supportive environment where everyone can share their experiences and contribute.</p>
          <p>By offering data and reports on all actions taken, we make sure that our customers are always informed. This enables us to streamline the procedure and create a welcoming atmosphere where everyone, including the management, is informed.</p>

        </div>

      </section>

      <section id="services" className="services">
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>Services</h2>
            <p>Explore unlimited possibilities with Surrealance Multiservice</p>
          </div>
          <div className="row" data-aos="fade-up" >
            <div className="col-lg-4 col-md-6 icon-box">
              <div className="icon">
                <i class="bi bi-telephone"></i>
              </div>
              <h4 className="title">
                <a>Cold calling</a>
              </h4>
              <p className="description">
                Call a potential customer to see if they are interested in a product or service you are offering. This task is crucial in the sales process because it identifies the people who are most likely to try the service or product by increasing the number of connections made.
              </p>
            </div>
            <div className="col-lg-4 col-md-6 icon-box">
              <div className="icon virtual-assistant">
                <img src={service_1} alt="Virtual assistant / Acquisition manager" />
              </div>
              <h4 className="title">
                <a>Virtual assistant / Acquisition manager</a>
              </h4>
              <p className="description">
                An acquisitions manager negotiates, seeks out, closes, and organizes purchasing transactions.
              </p>
              <p className="description">Virtual assistants will handle a variety of administrative tasks, such as responding to emails, scheduling meetings, and making travel arrangements. and much more high-quality work with little supervision.</p>
            </div>
            <div className="col-lg-4 col-md-6 icon-box">
              <div className="icon">
                <i className="bi bi-calendar4-week" />
              </div>
              <h4 className="title">
                <a>Appointment setters / Sales agents</a>
              </h4>
              <p className="description">
                An appointment setter is responsible for contacting potential customers and scheduling appointments for his or her company
              </p>
              <p className="description">
                Throughout the sales process, a sales agent interacts directly with customers. They are in charge of identifying a customer's needs, pitching relevant products or services, and ensuring a positive experience from beginning to end.
              </p>
            </div>
            <div className="col-lg-4 col-md-6 icon-box">
              <div className="icon">
                <img src={service_2} alt="Customer care" />
              </div>
              <h4 className="title">
                <a>Customer care</a>
              </h4>
              <p className="description">
                A Customer Service Representative (CSR) is the first point of contact for any customer who has a question or an issue with one of the company's products or services. They are responsible for a variety of tasks such as answering inbound phone calls, addressing customer questions about products and services, and processing payments or returns.
              </p>
            </div>
            <div className="col-lg-4 col-md-6 icon-box">
              <div className="icon">
                <img src={service_3} alt="Technical support" />
              </div>
              <h4 className="title">
                <a>Technical support</a>
              </h4>
              <p className="description">
                Tech specialists manage, maintain, and repair information technology systems. Their duties include fault diagnosis and repair, network troubleshooting, and installing and configuring hardware and software.
              </p>
            </div>
            <div className="col-lg-4 col-md-6 icon-box">
              <div className="icon">
                <img src={service_4} alt="Social media / Live chat / Email support" />
              </div>
              <h4 className="title">
                <a>Social media / Live chat / Email support</a>
              </h4>
              <p className="description">
                Responding to customer inquiries through Chat, Email, and Social Media. providing the right information. Responding to customers' needs, complaints, or other issues with products or services.
              </p>
            </div>
          </div>
        </div>

        <div id="tools" className="tools section-bg ">
          <OwlCarousel
            className="owl-theme"
            center={true}
            items={6}
            loop
            margin={0}
            dots={false}
            autoplay={true}
            autoplayHoverPause
            autoplayTimeout={1500}
            autoplaySpeed={1000}
            responsive={toolsResponsive}
          >
            <div class="item tools-p">
              We use these tools to <br/> serve your business.
            </div>
            <div class="item">
              <img src={tools_1} alt='tool' />
            </div>
            <div class="item">
              <img src={tools_2} alt='tool' />
            </div>
            <div class="item">
              <img src={tools_3} alt='tool' />
            </div>
            <div class="item">
              < img src={tools_4} alt='tool' />
            </div>
            <div class="item">
              <img src={tools_5} alt='tool' />
            </div>
            <div class="item">
              <img src={tools_6} alt='tool' />
            </div>
            <div class="item">
              <img src={tools_7} alt='tool' />
            </div>
            <div class="item">
              <img src={tools_8} alt='tool' />
            </div>
            <div class="item">
              <img src={tools_9} alt='tool' />
            </div>
            <div class="item">
              <img src={tools_10} alt='tool' />
            </div>
            <div class="item">
              <img src={tools_11} alt='tool' />
            </div>
            <div class="item">
              <img src={tools_12} alt='tool' />
            </div>
            <div class="item">
              <img src={tools_13} style={{maxWidth:"60%"}} alt='tool' />
            </div>
            <div class="item">
              <img src={tools_14}  alt='tool' />
            </div>
            <div class="item">
              <img src={tools_15} alt='tool' />
            </div>
          </OwlCarousel>
        </div>
      </section>


      <section id="industry" className="industries ">
        <div className="container">
          <div className="section-title">
            <h2>industries</h2>
            <p>Surrealance Multiservice serves a wide range of industries</p>
          </div>
          <div className="row gy-4">
            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-right"

            >
              <div className="card">
                <div className="card-img">
                  <img
                    src={industry_1}
                    alt="real estate industry"
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a className="stretched-link">
                    REAL ESTATE
                  </a>
                </h3>
                <p>
                  Increase the size of your real estate business. Allow our agents to assist you in filling your pipeline and closing more deals.
                </p>
              </div>
            </div>
            {/* End Card Item */}
            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-right"
              data-aos-delay={200}
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src={industry_2}
                    alt="solar industry"
                    className="img-fluid"

                  />
                </div>
                <h3>
                  <a className="stretched-link">
                    SOLAR
                  </a>
                </h3>
                <p>
                  Our representatives assist you with customer service, appointment setting, and sales. Increasing the total size of your business.
                </p>
              </div>
            </div>
            {/* End Card Item */}
            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-right"
              data-aos-delay={400}
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src={industry_6}
                    alt="Insurance"
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a className="stretched-link">
                    Insurance
                  </a>
                </h3>
                <p>
                  Increase the number of people you connect with. Our specialized agents will handle all of your leads and phone calls, allowing you to focus on your business. We also help with customer service issues.
                </p>
              </div>
            </div>
            {/* End Card Item */}
            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-right"

            >
              <div className="card">
                <div className="card-img">
                  <img
                    src={industry_3}
                    alt="E-Com and Retail"
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a className="stretched-link">
                    E-Com and Retail
                  </a>
                </h3>
                <p>
                  Give your online or retail business the customer service and support it deserves. We assist in reaching out to customers to ensure that orders have been received. We also help with customer service issues.
                </p>
              </div>
            </div>
            {/* End Card Item */}
            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-right"
              data-aos-delay={200}
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src={industry_4}
                    alt="MEDICAL"
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a className="stretched-link">
                    MEDICAL
                  </a>
                </h3>
                <p>
                  We assist in contacting customers to ensure that their orders have been received. We also assist with customer service problems.
                </p>
              </div>
            </div>
            {/* End Card Item */}
            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-right"
              data-aos-delay={200}
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src={industry_5}
                    alt="AUTOMOTIVE"
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a className="stretched-link">
                    AUTOMOTIVE
                  </a>
                </h3>
                <p>
                  We make sure your clients can always get help from someone when they need it when it comes to their cars.
                </p>
              </div>
            </div>
            {/* End Card Item */}

          </div>

        </div>
      </section>

      <section className='pricing ' id="pricing">
        <div className="container" >
          <div className="section-title">
            <h2>Pricing</h2>
            <p>
              There is a plan for you, no matter where you are in your investing journey...
            </p>
          </div>
          <div className='pricing-cards row gy-4'>
            <Link to="/#contact" scroll={el => scrollWithOffset(el)} className='pricing-card col-lg-4 col-md-6' data-aos="flip-up">
              <div className="card-title">
                <h3>
                  Starter
                </h3>
              </div>
              <div className='card-divider'></div>
              <div className='card-info'>
                <div className="card-info-heading">
                  <h4
                    overridecolor=""
                    className=""
                  >
                    $1200

                    <span className="month-text">
                      <span>/month per agent</span>

                    </span>
                  </h4>
                  <div class="h4 mt-2">1-2 agents</div>
                </div>
                <ul >
                  <li
                    textcolor="#4d5b7c"
                    className="PricingStaticCardsStyles__StyledFeatureItem-sc-39bg21-6 jvpNBw"
                  >
                    <img
                      alt="checkmark"
                      src={pricingImage}
                    />
                    8 Hours a day 5 days a week
                  </li>
                  <li
                    textcolor="#4d5b7c"
                    className="PricingStaticCardsStyles__StyledFeatureItem-sc-39bg21-6 jvpNBw"
                  >
                    <img
                      alt="checkmark"
                      src={pricingImage}
                    />
                    Experienced Cold Caller
                  </li>
                  <li
                    textcolor="#4d5b7c"
                    className="PricingStaticCardsStyles__StyledFeatureItem-sc-39bg21-6 jvpNBw"
                  >
                    <img
                      alt="checkmark"
                      src={pricingImage}
                    />
                    Client Success Manager
                  </li>
                  <li
                    textcolor="#4d5b7c"
                    className="PricingStaticCardsStyles__StyledFeatureItem-sc-39bg21-6 jvpNBw"
                  >
                    <img
                      alt="checkmark"
                      src={pricingImage}
                    />
                    Quality Control Manager
                  </li>
                  <li
                    textcolor="#4d5b7c"
                    className="PricingStaticCardsStyles__StyledFeatureItem-sc-39bg21-6 jvpNBw"
                  >
                    <img
                      alt="checkmark"
                      src={pricingImage}
                    />
                    Predictive dialer with rotating numbers
                  </li>
                  <li
                    textcolor="#4d5b7c"
                    className="PricingStaticCardsStyles__StyledFeatureItem-sc-39bg21-6 jvpNBw"
                  >
                    <img
                      alt="checkmark"
                      src={pricingImage}
                    />
                    Lead Call Recordings
                  </li>
                  <li
                    textcolor="#4d5b7c"
                    className="PricingStaticCardsStyles__StyledFeatureItem-sc-39bg21-6 jvpNBw"
                  >
                    <img
                      alt="checkmark"
                      src={pricingImage}
                    />
                    Daily and weekly reports
                  </li>
                </ul>
              </div>
              <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                <button>Contact Us</button>
              </div>
            </Link>

            <Link to="/#contact" scroll={el => scrollWithOffset(el)} className='pricing-card col-lg-4 col-md-6' data-aos="flip-up" data-aos-delay={200}>
              <div className="card-title">
                <h3>
                  Standard
                </h3>
              </div>
              <div className='card-divider'></div>
              <div className='card-info'>
                <div className="card-info-heading">
                  <h4
                    overridecolor=""
                    className=""
                  >
                    $1040
                    <span className="month-text">
                      <span>/month per agent</span>
                    </span>
                  </h4>
                  <div class="h4 mt-2">3-5 agents</div>
                </div>
                <ul >
                  <li
                    textcolor="#4d5b7c"
                    className="PricingStaticCardsStyles__StyledFeatureItem-sc-39bg21-6 jvpNBw"
                  >
                    <img
                      alt="checkmark"
                      src={pricingImage}
                    />
                    8 Hours a day 5 days a week
                  </li>
                  <li
                    textcolor="#4d5b7c"
                    className="PricingStaticCardsStyles__StyledFeatureItem-sc-39bg21-6 jvpNBw"
                  >
                    <img
                      alt="checkmark"
                      src={pricingImage}
                    />
                    Experienced Cold Caller
                  </li>
                  <li
                    textcolor="#4d5b7c"
                    className="PricingStaticCardsStyles__StyledFeatureItem-sc-39bg21-6 jvpNBw"
                  >
                    <img
                      alt="checkmark"
                      src={pricingImage}
                    />
                    Client Success Manager
                  </li>
                  <li
                    textcolor="#4d5b7c"
                    className="PricingStaticCardsStyles__StyledFeatureItem-sc-39bg21-6 jvpNBw"
                  >
                    <img
                      alt="checkmark"
                      src={pricingImage}
                    />
                    Quality Control Manager
                  </li>
                  <li
                    textcolor="#4d5b7c"
                    className="PricingStaticCardsStyles__StyledFeatureItem-sc-39bg21-6 jvpNBw"
                  >
                    <img
                      alt="checkmark"
                      src={pricingImage}
                    />
                    Predictive dialer with rotating numbers
                  </li>
                  <li
                    textcolor="#4d5b7c"
                    className="PricingStaticCardsStyles__StyledFeatureItem-sc-39bg21-6 jvpNBw"
                  >
                    <img
                      alt="checkmark"
                      src={pricingImage}
                    />
                    Lead Call Recordings
                  </li>
                  <li
                    textcolor="#4d5b7c"
                    className="PricingStaticCardsStyles__StyledFeatureItem-sc-39bg21-6 jvpNBw"
                  >
                    <img
                      alt="checkmark"
                      src={pricingImage}
                    />
                    Daily and weekly reports
                  </li>
                </ul>

              </div>
              <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                <button>Contact Us</button>
              </div>
            </Link>
            <Link to="/#contact" scroll={el => scrollWithOffset(el)} className='pricing-card col-lg-4 col-md-6' data-aos="flip-up" data-aos-delay={400}>
              <div className="card-title">
                <h3>
                  Saver
                </h3>
              </div>
              <div className='card-divider'></div>
              <div className='card-info'>
                <div className="card-info-heading">
                  <h4
                    overridecolor=""
                    className=""
                  >
                    $960
                    <span className="month-text">
                      <span>/month per agent</span>
                    </span>
                  </h4>
                  <div class="h4 mt-2">6+ agents</div>
                </div>
                <ul >
                  <li
                    textcolor="#4d5b7c"
                    className="PricingStaticCardsStyles__StyledFeatureItem-sc-39bg21-6 jvpNBw"
                  >
                    <img
                      alt="checkmark"
                      src={pricingImage}
                    />
                    8 Hours a day 5 days a week
                  </li>
                  <li
                    textcolor="#4d5b7c"
                    className="PricingStaticCardsStyles__StyledFeatureItem-sc-39bg21-6 jvpNBw"
                  >
                    <img
                      alt="checkmark"
                      src={pricingImage}
                    />
                    Experienced Cold Caller
                  </li>
                  <li
                    textcolor="#4d5b7c"
                    className="PricingStaticCardsStyles__StyledFeatureItem-sc-39bg21-6 jvpNBw"
                  >
                    <img
                      alt="checkmark"
                      src={pricingImage}
                    />
                    Client Success Manager
                  </li>
                  <li
                    textcolor="#4d5b7c"
                    className="PricingStaticCardsStyles__StyledFeatureItem-sc-39bg21-6 jvpNBw"
                  >
                    <img
                      alt="checkmark"
                      src={pricingImage}
                    />
                    Quality Control Manager
                  </li>
                  <li
                    textcolor="#4d5b7c"
                    className="PricingStaticCardsStyles__StyledFeatureItem-sc-39bg21-6 jvpNBw"
                  >
                    <img
                      alt="checkmark"
                      src={pricingImage}
                    />
                    Predictive dialer with rotating numbers
                  </li>
                  <li
                    textcolor="#4d5b7c"
                    className="PricingStaticCardsStyles__StyledFeatureItem-sc-39bg21-6 jvpNBw"
                  >
                    <img
                      alt="checkmark"
                      src={pricingImage}
                    />
                    Lead Call Recordings
                  </li>
                  <li
                    textcolor="#4d5b7c"
                    className="PricingStaticCardsStyles__StyledFeatureItem-sc-39bg21-6 jvpNBw"
                  >
                    <img
                      alt="checkmark"
                      src={pricingImage}
                    />
                    Daily and weekly reports
                  </li>
                </ul>

              </div>
              <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                <button>Contact Us</button>
              </div>
            </Link>
            <div className='disclaimer'><p><u>Disclaimer:</u> These prices are for cold calling service. If you'd like to get a quote on a different type of service, please contact us.</p></div>
          </div>
        </div>
      </section>
      <section id="contact" className="contact ">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Contact</h2>
            <p>
              Schedule a call with our team and we'll go over all the details you need to know to make the best decision for your company. We're here to help you succeed!
            </p>
          </div>
          <div className="row">
            <div className="col-lg-5 d-flex align-items-stretch">
              <div className="info" data-aos="fade-right" data-aos-delay={50}>

                <div className="email">
                  <i className="bi bi-envelope" />
                  <h4>Email:</h4>
                  <p>info@surrealance.com</p>
                </div>
                <div className="phone">
                  <i className="bi bi-phone" />
                  <h4>Call:</h4>
                  <p>+1 469-638-3505</p>
                </div>
              </div>
            </div>
            <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
              <form

                className="php-email-form"
                id='email-form'
                data-aos="fade-left"
                data-aos-delay={50}
              >
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="name">Your Name</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      required=""
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="name">Your Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      required=""
                      onChange={(e) => setEmailValue(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="name">Phone Number</label>
                  <input
                    type="tel"
                    className="form-control"
                    name="Phone"
                    id="Phone"
                    required=""
                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="name">Subject</label>
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    required=""
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="name">Message</label>
                  <textarea
                    className="form-control"
                    name="message"
                    rows={10}
                    required=""
                    defaultValue={""}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message" />
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                </div>
                <div className="text-center">
                  <button onClick={Handlesubmit} type="submit" >Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </div>
  )
}
