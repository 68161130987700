import { useEffect } from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import "./nav.css"
import logo from './logo.png'

export default function Navbar() {
  useEffect(() => {
    let sections = document.querySelectorAll('section');
    let navLinks = document.querySelectorAll('header nav a');
    var prevScrollpos = window.pageYOffset;

    window.onscroll = () => {

      if (window.scrollY > 0) {
        document.getElementById("header").classList.add('scrolled')
      }
      if (window.scrollY === 0) {
        document.getElementById("header").classList.remove('scrolled')

      }

      sections = document.querySelectorAll('section');
      navLinks = document.querySelectorAll('header nav a');

      sections.forEach(sec => {

        let top = window.scrollY;
        let offset = sec.offsetTop - 150;
        let height = sec.offsetHeight;
        let id = sec.getAttribute('id');

        if (top >= offset && top < offset + height) {
          navLinks.forEach(links => {
            links.classList.remove('active');
            if (document.querySelector('header nav a[href*=' + id + ']')) {
              document.querySelector('header nav a[href*=' + id + ']').classList.add('active');
            }
          });
        };
      });

      if (window.orientation !== undefined) {
        if (window.innerWidth <= 768) {

          var currentScrollPos = window.pageYOffset;
          if (prevScrollpos > currentScrollPos) {
            document.getElementById("header").style.top = "0";
          } else {
            document.getElementById("header").style.top = "-88px";
          }
          prevScrollpos = currentScrollPos;

        }
      }
    };



  }, [])


  function toggleMobileNav() {
    document.getElementById("header").classList.toggle('mobile-nav-active')
    document.getElementById("nav-icon").classList.toggle('bi-list')
    document.getElementById("nav-icon").classList.toggle('bi-x')

  }
  // function NavScroll(){
  //   document.addEventListener('scroll',function(e,header){

  //   if (window.scrollY > 0){
  //     document.getElementById("header").classList.add('scrolled')
  //   }else{
  //     document.getElementById("header").classList.remove('scrolled')
  //   }


  //   })

  // }
  // NavScroll()
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    let yOffset = -80;
    if (window.scrollY === 0) {
      yOffset = -150;
    }
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });

  }
  return (
    <header id="header" className="header d-flex align-items-center ">
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <Link to="/#" className="logo d-flex align-items-center ">
          {/* Uncomment the line below if you also wish to use an image logo */}
          <img src={logo} alt="logo" />
          <h1>
            Surrealance
            {/* <span>.</span> */}
          </h1>
        </Link>
        <i className="mobile-nav-toggle mobile-nav-show bi bi-list" id='nav-icon' onClick={toggleMobileNav} />
        <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x" />
        <nav id="navbar" className="navbar">
          <ul>
            <li onClick={toggleMobileNav}>
              <Link to="/#about" className="active about" scroll={el => scrollWithOffset(el)}>
                ABOUT
              </Link>
            </li>
            <li onClick={toggleMobileNav}>
              <Link to="/#services" className='services' scroll={el => scrollWithOffset(el)}>SERVICES</Link>
            </li>
            <li onClick={toggleMobileNav}>
              <Link to="/#industry" className='industry' scroll={el => scrollWithOffset(el)}>INDUSTRIES</Link>
            </li>
            <li onClick={toggleMobileNav}>
              <Link to="/#pricing" className="pricing" scroll={el => scrollWithOffset(el)}>PRICING</Link>
            </li>
            <li onClick={toggleMobileNav}>
              <Link to="/#contact" className="contact" scroll={el => scrollWithOffset(el)}>Contact</Link>
            </li>
            <li onClick={toggleMobileNav}>
              <Link to="/affiliates#" className='affiliates' >affiliates</Link>
            </li>
            <li>
              <div class="nav-social-links pl-2 mt-1">
                <a href="https://twitter.com/Surrealance" target="_blank" class="twitter">
                  <i class="bx bxl-twitter"></i>
                </a>
                <a href="https://www.facebook.com/Surrealance" target="_blank" class="facebook">
                  <i class="bx bxl-facebook"></i>
                </a>
                <a href="https://www.instagram.com/surrealance/" target="_blank" class="instagram">
                  <i class="bx bxl-instagram"></i>
                </a>
                <a href="https://www.linkedin.com/company/surrealance" target="_blank" class="linkedin">
                  <i class="bx bxl-linkedin"></i>
                </a>
              </div>

            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}
