import { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';
import Navbar from './components/navbar/navbar';
import Footer from './components/footer/footer';
import Home from './pages/homepage/home';
import Affiliates from './pages/affiliates/affiliates';
import Faqs from './pages/faqs/faqs';
import Privacy from './pages/privacy/privacy';
import Agreement from './pages/serviceAgreement/agreement';


import Aos from 'aos';
import 'aos/dist/aos.css'


import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';





function App() {
  useEffect(() => {
    Aos.init({
      duration: 500,
      once: true,
      offset: window.innerWidth > 768 ? 150 : 0,
      // disable: window.innerWidth > 768 ? false : true,
    });
  }, [])
  const queryClient = new QueryClient();
  return (
    <div className="App wrap">
      <QueryClientProvider client={queryClient}>
        <Router>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/affiliates" element={<Affiliates />} />
            <Route exact path="/faqs" element={<Faqs />} />
            <Route exact path="/privacy" element={<Privacy />} />
            <Route exact path="/service-agreement" element={<Agreement />} />
          </Routes>
          <Footer />
        </Router>
      </QueryClientProvider>



    </div>
  );
}

export default App;
