import { useState, useEffect } from 'react';

import "./agreement.css"


export default function Agreement() {
  return (

    <section id='agreement' className='container agreement first-section' data-aos="fade-zoom-in">
      <div className="agreement-header">
        <h3 className="agreement-title">Service Agreement</h3>
        <div className="seperator"></div>
      </div>
      <p>
        This Service Agreement (This “Agreement”) is made effective as of this day of, ______ <i>(the “Effective date), by and between </i>______ (“Client”) and Surrealance Multiservice (“S.M.S”).
      </p>
      <div>
        <h4>RECITALS</h4>
        <div>
          <p>
            S.M.S provides call center services, and Client desires to engage S.M.S to provide such
            services, on the terms and conditions set forth in this Agreement.
          </p>
        </div>
      </div>
      <div>
        <h4>AGREEMENT</h4>
        <p>
          Client and S.M.S, in consideration of the mutual promises, terms, and conditions provided herein, agree as follows:
        </p>
        <ul className='ordered-list'>
          <li>
            <strong>Services. </strong>During the Term of this Agreement (as specified in Paragraph 5(a), below), S.M.S will provide call center services to Client.
          </li>
          <li>
            <strong>S.M.S’ Obligations. </strong>During the Term of this Agreement, S.M.S will fulfill the<br />
            following obligations:

            <ul className='letters-list'>
              <li>Quality of Services. S.M.S will render all Services in a professional, diligent, honest manner, consistent with reasonable standards of quality for comparable services in the industry. S.M.S is responsible for supervision, quality control, and training of agents.
              </li>
              <li>Number of Agents. S.M.S will provide Services through its agents, each of whom shall provide up to 45 hours per week of Services to clients.
              </li>
              <li>Reporting. S.M.S will provide clients with a list check and progress report on a weekly basis and shall provide such report(s) via email.
              </li>
              <li>Billing. S.M.S will bill clients according to the time periods set forth in
                Paragraph 4(b), Surrealance shall provide clients with an accurate total hours of services that were actually completed in each monthly period. It is the client's responsibility to ensure all invoices are paid on the due date. In the event the past due amount falls more than 48 hours, S.M.S
                will process the card on file for outstanding balance. If the client cannot be contacted or the card on file is rejected, client campaigns will be put on hold and the client will forfeit all deposits received. If the invoice is not satisfied after 72 hours the deposit will be applied to the
                outstanding balance. The client accounts will be placed in collections until full payment is received. Please note that we might use the services of a third party agency to recover outstanding balance if your account exceeds 30 days over due.
              </li>
              <li>Compliance with Laws. In providing the Services, S.M.S shall comply with all applicable federal, state, and local laws, orders, rules, ordinances, regulations, and codes.
              </li>
            </ul>
          </li>
          <li>
            <strong>Client’s Obligations. </strong>At all times during the Term, Client will fulfill the<br />
            following obligations:

            <ul className='letters-list'>
              <li>Agent commitment. Client commits to _____ agents for their campaign. Hereafter, the client shall provide two-week advance notice of any projected changes in the number of agents or services required. This includes downsizing or upscaling numbers of agents and/or
                placing the services on hold by request.
              </li>
              <li>Data. If the services selected by the Client require data, the Client shall provide data for use by S.M.S to deliver the service requested. The client will provide a sufficient level of data to ensure the agent's productivity. Client is responsible for ensuring such data is compliant
                with the requirements of the Telephone Consumer Protection Act (“TCPA”).

              </li>
              <li>Deposit. It is the client's responsibility to have a deposit on file within 24 hours of signing this agreement. Client agrees to provide S.M.S with a deposit equal to the projected amount of 2 business weeks of Services to be provided based upon the Agent Commitment noted in Paragraph 3(a) (the “Deposit”). S.M.S shall provide a calculation of this amount upon signing this agreement which will remain on the account for the entire term. S.M.S shall be entitled to utilize the security deposit to satisfy any amount due to S.M.S under this Agreement and/or as compensation for any damage or loss that Provider may suffer as a result of Client’s breach of this Agreement. S.M.S will be authorized to collect the total amount of the remaining deposit if Client abandons or does not use the account for over 90 Days.
              </li>
              <li>Once the client agrees to the terms of service, the client commits to a two-month service through S.M.S Per this agreement the client abides to a two-month period, if the service is ended or stopped S.M.S has the legal right to take full legal action.
              </li>
              <li>S.M.S will not provide refunds but will use any remaining balance towards outstanding service charges.
              </li>
            </ul>
          </li>
          <li><b>Fees and Payment.</b>
            <ul className='letters-list'>
              <li>Client will pay __ biweekly per agent for the Services (the "Fees").</li>
              <li>S.M.S will invoice the client twice a month and such invoice shall set for the Fees payable for the immediately preceding two-week period. Client will be billed for the actual hours its assigned Agents are logged in to the S.M.S system and providing services. All invoices will be due in the next 2 days. If any payment owed to S.M.S is not paid when due,
                then (i) no further services will be provided until such invoice is paid; (ii) S.M.S will deduct the amount owed under the invoice from the deposit.</li>
              <li>Additional Fees:
                <ul className='roman-list'>
                  <li>An additional 3% Credit Card Processing Fees will be applied when invoices are paid using a Credit card.</li>
                  <li>In Case of Using ACH transfer, no additional Fees to be applied to the invoice.</li>
                </ul>
              </li>

            </ul>
          </li>
          <li>
            <b>Term and Termination.</b>
            <ul className='letters-list'>
              <li>Term. Service start date is ___________________. This Agreement will continue until terminated as provided under this Agreement.</li>
              <li>Termination. Client acknowledges that S.M.S will make a substantial investment to fulfill its obligations under this agreement. As a result, (S.M.S) will require a two business weeks written cancellation notice prior to the billing cycle in order to terminate services.</li>
              <li>Paused. Clients can pause an account if needed up to a total of 12 months, 3 months at a time and it is the client’s responsibility to extend such “Pause“ to the time allowed, this must be via email. If the client fails to extend we will change the account from Paused to Active services will continue as per the agreement.</li>
            </ul>
          </li>
          <li>
            <b>Independence.</b>
            <p>S.M.S is rendering the Services hereunder as an independent service provider, and not as an employee, joint venture, or partner, of Client. For the avoidance of doubt, the parties expressly state that nothing in this Agreement is intended to create any joint employer relationship between S.M.S and Client.</p>
            <ul className='letters-list'>
              <li>S.M.S shall have the sole right to determine the time, place, and manner in which S.M.S and its operators and/or subcontractors render the Services required by this Agreement.
                It is expressly understood and agreed between the parties that, in exercising its discretion regarding the place from which Services are rendered under this Agreement, S.M.S may utilize subcontractors or other personnel located outside of the United States or its territories.</li>
              <li>Each party agrees to assume complete responsibility for its own personnel and/or subcontractors, to the extent applicable, with regard to applicable taxes or liability associated with employing any individual to provide the Services under this Agreement.'</li>
            </ul>
          </li>
          <li><b>Non-Exclusivity. </b>The Parties understand and agree that nothing in this Agreement requires either party to exclusively contract with the other party for the Services or similar services. Specifically, S.M.S may contract with any other party, including, without limitation, an affiliate of S.M.S, to provide the Services or similar services, and Client may contract with any other call center to provide the Services or substantially similar services. Client acknowledges that S.M.S may work with clients and/or S.M.S’ affiliates, who work in similar industries to Client, and who may be engaging S.M.S for similar purposes and utilization of similar data profiles. Client acknowledges that such similarities add to S.M.S’ expertise in such industries. However, S.M.S will, at all times, maintain Client’s data confidentially from other clients’ data, and Client acknowledges and waives any claim against S.M.S based solely on the similarity (or competitiveness) of other clients’ purposes and services or products.</li>
          <li>
            <b>Use of Client Name and Marks. </b>Client hereby grants to S.M.S a non-exclusive, royalty-free, non-transferable right and license to use Client’s name and any logos, trademarks, trade names, or other marks of Client provided by Client to S.M.S (collectively, the “Client Marks”) exclusively for the purpose of providing the Services under this Agreement. Upon termination of this Agreement, such license shall automatically expire.
          </li>
          <li><b>Non-Circumvention. </b>Client recognizes that in the course of S.M.S’ provision of the Services pursuant to this Agreement, Client will be exposed to and learn detailed information regarding S.M.S’ subcontracted service providers who may be involved in providing services to Client under this Agreement, and hereby recognizes and acknowledges that such information would not be obtained in the absence of S.M.S’ role in connection with the Services. Accordingly, for the protection of S.M.S’ legitimate business interests, Client expressly agrees that during the Term of this Agreement and for a period of 12 months thereafter, neither Client nor any of its affiliates will, directly or indirectly, solicit, seek to procure, or procure services from any employee or subcontractor of S.M.S (other than by general advertising) who provided services for Client at any time during the 12 months preceding the commencement of such solicitation.</li>
          <li><b>Injunctive Rights. </b>Any breach of Section 8 of this Agreement will result in irreparable and continuing damage to the non-breaching party. In addition to any other rights or remedies the non-breaching party may have, any breach or threatened breach of the provisions of Section 8, above, may be prohibited by restraining order, injunction or any other equitable remedies. The rights and obligations established by Section 8 shall survive and continue after the termination or expiration of this
            Agreement.</li>
          <li><b>Indemnification. </b>Each party (the “Indemnifying Party”) will indemnify, defend, and hold harmless the other party (the “Indemnified Party”) and the Indemnified Party’s officers, directors, shareholders, members, managers, employees, agents, representatives, successors, heirs, and assigns (collectively, the “Related Parties) from and against all third-party claims, suits, damages, expenses (including reasonable attorneys’ fees and costs), penalties, and fines (collectively, “Losses”) arising out of or in connection with the Indemnifying Party’s breach or alleged breach of its obligations under this Agreement, including, without limitation, any breach of any obligation to ensure compliance with the
            TCPA. The Indemnified Party shall promptly provide the Indemnifying Party written notice regarding any third-party claim of which it has knowledge that is covered by this Section 10. Indemnified Party to give such notice shall not release, waive or otherwise affect the Indemnifying
            Party’s obligations with respect thereto except to the extent that the Indemnifying Party is actually and materially prejudiced as a result of such failure. The Indemnified Party shall have the right to assume and
            conduct the defense of such third-party claim with counsel of its choice reasonably satisfactory to the Indemnifying Party and the Indemnified Party may pay, compromise, settle or defend such third-party
            claim at the cost and expense of the Indemnifying Party in such manner and on such terms as it may deem reasonably appropriate; provided, that the Indemnified Party shall not be entitled to settle such
            third-party claim without the consent of the Indemnifying Party (such consent not to be unreasonably withheld, conditioned or delayed), unless the sole relief granted is relief for which the Indemnifying
            Party would have no liability, in which case no consent shall be required. Subject to the foregoing, no action taken by the Indemnified Party in accordance with such defense and settlement shall relieve the
            Indemnifying Party of its indemnification obligations provided herein with respect to any Losses resulting therefrom. The Indemnified Party’s defense and, if applicable, settlement of a third-party claim
            shall be at the cost and expense of the Indemnifying Party as if the Indemnifying Party is ultimately deemed to have an indemnification obligation hereunder with respect to such third-party claim. The
            Indemnified Party shall, to the extent reasonably requested by the Indemnifying Party, keep the Indemnifying Party reasonably informed as to the status of any third-party claim.</li>
          <li><b>Limitations on Liability. </b>In no event will either party be liable to the other for aggregate
            damages, including indemnification obligations, under this Agreement in excess of 12 months’ Fees incurred hereunder. NEITHER PARTY WILL BE LIABLE TO THE OTHER FOR CONSEQUENTIAL,
            INDIRECT, SPECIAL OR PUNITIVE DAMAGES FOR ANY CAUSE OF ACTION, WHETHER IN CONTRACT,
            TORT, OR OTHERWISE.</li>
          <li><b>Force Majeure. </b> In the event that either party is unable to perform any of its obligations under this Agreement because of natural disaster, actions or decrees of governmental bodies or agencies,
            war, civil disturbances, terrorism or communication line failure, or other cause not the fault of the affected party (a “Force Majeure Event”), the party that has been so affected shall immediately give notice
            to the other party and shall do everything possible to resume performance. Upon receipt of such notice, all obligations under this Agreement shall be immediately suspended. If the period of nonperformance exceeds 30 days from the receipt of notice of the Force Majeure Event, the party whose ability has not
            been so affected, may, by giving written notice, terminate this Agreement.</li>
          <li><b>Severability; Waiver. </b>Client and S.M.S agree that, if any term or provision of this Agreement is declared by a court of competent jurisdiction to be illegal or in conflict with any law, the validity of the remaining terms and provisions shall not be affected, and the rights and obligations of the parties shall be construed and enforced as if this Agreement did not contain the particular term or provision held to be invalid. The failure of either party to enforce any provision of this Agreement shall not constitute a waiver by that party of that or any other provision of this Agreement.</li>
          <li><b>Notice. </b>All notices under this Agreement shall be in writing and addressed to the appropriate party at the address set forth immediately below, or at such other address as either party may subsequently advise in writing in accordance with the terms.</li>
          <li><b>Entire Agreement. </b>This Agreement represents the entire understanding and agreement between the parties with respect to the subject matter hereof, and supersedes all other negotiations, understandings and representations made by and between the parties with respect to such subject matter.</li>
          <li><b>Binding Agreement; Assignment. </b>This Agreement shall inure to the benefit of each party and their respective successors, heirs, and assigns; provided, however, that neither party may assign this
            Agreement without the prior written consent of the other.</li>
          <li><b>Counterparts; Electronic Signatures. </b>This Agreement may be executed in any number of counterparts, each of which shall be deemed to be an original, and all of which together will constitute one and the same instrument. A photocopy, electronic, or digital copy of a handwritten signature will be deemed to be an original signature for all purposes.</li>
          <li><b>Liability and Outsourcing; </b>(S.M.S) is considered a third-party marketing and billing vendor used in the services rendered in this contract. A Variety of outsourced companies conduct the
            nature of business in which you are agreeing too. (S.M.S) holds no liability for any disputes, litigation, FTC complaints or damages incurred due to the services rendered from any outsourced companies.</li>
          <li><b>State and Federal Laws; </b>Client understands that services provided are voluntary. Client understands that (S.M.S) and Outsourced companies are contracted to provide services chosen by the
            client. Client understands potential liability and legal issues that may arise from chosen services and waives all right to claims against (S.M.S) and Outsourced Companies that may arise due to disputes,
            litigation, FTC Complaints, or damages from the services provided.</li>
          <li>Client agrees that (S.M.S) and Outsourced Companies may dissolve this contract, if Client does not comply with all applicable federal, state, and local laws, orders, rules, ordinances, regulations, and codes.</li>
          <li>S.M.S reserves the right to change or update this Agreement at any time. S.M.S will notify customers through an electronic link with the New Terms of Agreement.</li>
        </ul>
      </div>



    </section>



  )
}