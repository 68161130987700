import { useState, useEffect } from 'react';

import "./faqs.css"


export default function Faqs() {
  return (
    <section className='faq first-section' >
      <div id="faq" class="faq-body" data-aos="fade-zoom-in">
        <div class="faq-header">
          <h3 class="faq-title">FAQ's</h3>
          <div class="seperator"></div>
        </div>
        <div class="faq-list">
          <div>
            <details open>
              <summary title="How can I pay for my appointment?">What are your business hours?</summary>
              <p class="faq-content">Our clients can rely on us 24 hours a day, seven days a week.</p>
            </details>
          </div>
          <div>
            <details>
              <summary title="What can I expect at my first consultation?">When did you start in business?</summary>
              <p class="faq-content">S.M.S Was founded in 2023.</p>
            </details>
          </div>
          <div>
            <details>
              <summary title="What are your opening hours?">How many leads can I expect in a month from cold callers?</summary>
              <p class="faq-content">The number of leads you can expect per month per agent varies, and it really depends on the list, the market, and what you consider a valid lead. We have seen agents get up to 60–70 leads per month, which is equivalent to 2 leads per day per agent</p>
            </details>
          </div>
          <div>
            <details>
              <summary title="Do I need a referral?">How many calls will cold callers make per day on average?</summary>
              <p class="faq-content">Depending on the answer rate, one cold caller with a 5-line dialer in ReadyMode can make 1000+ calls per day on average. This equates to over 15,000 calls per month.</p>
            </details>
          </div>
          <div>
            <details>
              <summary title="Do I need a referral?">Will I only be charged if cold callers speak to the person? Or will I be charged if they leave a voicemail?</summary>
              <p class="faq-content">Our cold callers will call your list 3-4 times over the course of two weeks, using a list of 5,000 contacts. Excluding duplicate phone numbers. Using our dialer, our agents will make over 1000 calls per day. Every day, The goal is to connect with as many prospects as possible. You will be billed monthly based on the plan you have chosen, and there will be no charge based on the number of connections made.</p>
            </details>
          </div>
          <div>
            <details>
              <summary title="Is the cost of the appointment covered by private health insurance?">How is a representative assigned to me? Do I get to choose which one will work with me?</summary>
              <p class="faq-content">S.M.S. will hire, train, and manage your agent. You will communicate with your client success manager directly, who will provide you with a daily performance report. If you are dissatisfied with your current agent, they will be replaced right away. If our agents do not provide leads for more than three days in a row, they will be replaced.</p>
            </details>
          </div>
          <div>
            <details>
              <summary title="What are the parking and public transport options?">Are the calls being recorded?</summary>
              <p class="faq-content">All calls are, indeed, recorded. S.M.S Quality Control Manager will listen to all recordings, transcribe the information, and upload all recordings into your CRM.</p>
            </details>
          </div>
          <div>
            <details>
              <summary title="What are the parking and public transport options?">How do I know if the agents are working hard?</summary>
              <p class="faq-content">Our top priority is quality assurance. Our goal is to provide 100% customer satisfaction, and we constantly employ a variety of methods to ensure that all calls are handled professionally. To do so, we record the vast majority of calls across all clients for later review. Qa supervisors also listen in on live calls and provide immediate feedback to our agents. We also contact customers at random to solicit feedback. Furthermore, our phone system closely monitors hold time, talk time, and a variety of other statistics, which are reviewed daily by management.</p>
            </details>
          </div>
          <div>
            <details>
              <summary title="What are the parking and public transport options?">How do I contact you if I have any questions?</summary>
              <p class="faq-content">Please reach out to info@surrealance.com or feel free giving us a call on 469-638-3505</p>
            </details>
          </div>

        </div>
      </div>
    </section>
  )
}