import { useState, useEffect } from 'react';


import Modal from 'react-bootstrap/Modal';

import "./affiliates.css"
import affiliatesImg from "./affiliates.jpg"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validate } from 'react-email-validator';
export default function Affiliates() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [name, setName] = useState()
  const [message, setMessage] = useState()
  const [phone, setPhone] = useState()
  const [subject, setSubject] = useState()
  const [emailValue, setEmailValue] = useState()
  const [emailValid, setEmailValid] = useState(true)
  const postUrl = "https://mail.omarwasfi.com/EMail/SubmitSurrealanceReferralRegistrationForm"

  const notify = () => toast.success('Success', {
    className: 'toast-message'
  });
  const notValidNotify = () => toast.error('Not a valid email', {
    className: ''
  });


  function Handlesubmit(e) {
    e.preventDefault()
    const form = document.getElementById('email-form');
    let data = {
      name: name,
      email: emailValue,
      phoneNumber: phone,
      subject: subject,
      message: message,
    }
    //console.log(data)
    if (validate(emailValue)) {
      form.reset()
      handleClose()
      setEmailValue('')
      notify()
      //console.log("submit")
      fetch(postUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json-patch+json',
          'accept': 'text/plain'
        },
        body: JSON.stringify(data)
      })
        //.then(response => response.json())
        .then(data => {
          // console.log('Success:', data);
          data = ''
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    } else {
      setEmailValid(false)
      notValidNotify()
    }
  }

  return (
    <>
      <section id='affiliates' className='affiliates first-section' data-aos="fade-zoom-in" >
        {/*  */}
        <div className="container"  >
          <div className="row position-relative">
            <div
              className="col-lg-7 affiliates-img"
              style={{ backgroundImage: { affiliatesImg } }}
            />
            <div className="col-lg-7">
              <h2>Get CASH With Surrealance</h2>
              <div className="our-story">
                <h3>Affiliate Network</h3>
                <p>
                  We are currently assessing our Referral Program to see how it can benefit our customers and loyal affiliates. Please return later for updated information.
                </p>
                <ul>
                  <li>
                    <i className="bi bi-check-circle" />{" "}
                    <span>Simple Sign-Up Procedure</span>
                  </li>
                  <li>
                    <i className="bi bi-check-circle" />{" "}
                    <span>Tell Others About Surrealance</span>
                  </li>
                  <li>
                    <i className="bi bi-check-circle" />{" "}
                    <span>Get Quick Cash</span>
                  </li>
                </ul>
                {/* <p>
                  Vitae autem velit excepturi fugit. Animi ad non. Eligendi et non
                  nesciunt suscipit repellendus porro in quo eveniet. Molestias in
                  maxime doloremque.
                </p> */}
                <button onClick={handleShow}>Start Earning</button>
              </div>
            </div>
          </div>
          <div className='disclaimer mt-4'><p><u>Disclaimer:</u> All Payouts are based on a Net 30 payment cycle*</p><p>If a referral cancels their contract the affiliate is no longer eligible to receive commission*</p><p>All Referrals are subject to review and may be disqualified based on their qualifications*.</p><p>All Affiliates must abide by the disclosure agreement upon signing up.*</p><p>Terms and conditions apply.*</p></div>
        </div>




        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Surrealance Affiliate</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <form
              className="php-email-form affiliates"
              id='email-form'
            >
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="name">Your Name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    required=""
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="name">Your Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    required=""
                    onChange={(e) => setEmailValue(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="name">Phone Number</label>
                <input
                  type="tel"
                  className="form-control"
                  name="Phone"
                  id="Phone"
                  required=""
                  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="name">Subject</label>
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  required=""
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>

              <div className="form-group">
                <label htmlFor="name">Message</label>
                <textarea
                  className="form-control"
                  name="message"
                  rows={10}
                  required=""
                  defaultValue={""}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={Handlesubmit}>send</button>
          </Modal.Footer>
        </Modal>


      </section>
      <ToastContainer />
    </>
  )
}
